import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import Container from 'atoms/Container'
import TabContent from 'components/WinList/TabContent'

import { TabsRow, TabButton } from './styled'
import { getGameslists } from 'data/gameslist/selectors'
import { fetchGameslist } from 'data/gameslist/actions'

class WinList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: 'gamelog',
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(name) {
    const { fetchGameslist } = this.props
    this.setState({
      selectedTab: name,
    })

    fetchGameslist(name)
  }

  render() {
    const {
      intl: { messages },
      gameslist: { gamelog, recentwins, bigwins, rarewins },
    } = this.props
    const { selectedTab } = this.state

    const tabs = [
      {
        name: messages.Winlist.all,
        id: 'gamelog',
        data: gamelog && gamelog.data,
        showall: true,
      },
      {
        name: messages.Winlist.wins,
        id: 'recentwins',
        data: recentwins && recentwins.data,
      },
      {
        name: messages.Winlist.bigwins,
        id: 'bigwins',
        data: bigwins && bigwins.data,
      },
      {
        name: messages.Winlist.rarewins,
        id: 'rarewins',
        data: rarewins && rarewins.data,
      },
    ]

    return (
      <Container>
        <TabsRow>
          {tabs.map((item, idx) => (
            <TabButton
              key={item.name + idx}
              onClick={() => {
                this.handleClick(item.id)
              }}
              selected={selectedTab === item.id}
            >
              {item.name}
            </TabButton>
          ))}
        </TabsRow>

        {tabs.map((item, idx) => (
          <TabContent
            key={idx}
            data={item.data}
            tabId={item.id}
            betlist={item.betlist}
            showall={item.showall}
            selected={selectedTab === item.id}
          />
        ))}
      </Container>
    )
  }
}

const mapStateToProps = state => {
  const gameslist = getGameslists(state)
  return { gameslist }
}

const mapDispatchToProps = {
  fetchGameslist,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(WinList))
