// @flow

import React from "react";
import nouislider from "nouislider";

import "nouislider/distribute/nouislider.css";

class Slider extends React.Component {
  sliderContainer = ref => (this.sliderContainer = ref);
  componentDidMount() {
    const { clickablePips } = this.props;

    if (this.props.disabled)
      this.sliderContainer.setAttribute("disabled", true);
    else this.sliderContainer.removeAttribute("disabled");
    this.createSlider();
    if (clickablePips)
      this.sliderContainer.querySelectorAll(".noUi-value").forEach(pip => {
        pip.style.cursor = "pointer";
        pip.addEventListener("click", this.clickOnPip);
      });
  }

  componentDidUpdate() {
    if (this.props.disabled)
      this.sliderContainer.setAttribute("disabled", true);
    else this.sliderContainer.removeAttribute("disabled");
    this.slider.destroy();
    this.createSlider();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.range.max[0] !== this.props.range.max[0]) {
      return true;
    }

    return false;
  }

  clickOnPip = pip => {
    const value = Number(pip.target.getAttribute("data-value"));
    this.slider.set(value);
    this.props.onChange([value]);
  };

  componentWillUnmount() {
    this.slider.destroy();
  }

  createSlider() {
    const slider = (this.slider = nouislider.create(this.sliderContainer, {
      ...this.props
    }));

    if (this.props.onUpdate) {
      slider.on("update", this.props.onUpdate);
    }

    if (this.props.onChange) {
      slider.on("change", this.props.onChange);
    }

    if (this.props.onSlide) {
      slider.on("slide", this.props.onSlide);
    }

    if (this.props.onStart) {
      slider.on("start", this.props.onStart);
    }

    if (this.props.onEnd) {
      slider.on("end", this.props.onEnd);
    }

    if (this.props.onSet) {
      slider.on("set", this.props.onSet);
    }
  }

  render() {
    const { id, className, style } = this.props;
    const options = {};
    if (id) {
      options.id = id;
    }
    if (className) {
      options.className = className;
    }
    return (
      <div
        {...options}
        ref={slider => {
          this.sliderContainer = slider;
        }}
        style={style}
      />
    );
  }
}

export default Slider;
