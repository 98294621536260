import styled, { css } from 'styled-components'
import { media } from 'styles/utils'
import { Link } from 'gatsby'

export const Wrapper = styled.div`
  margin: 0;
  padding: 10px;
  max-height: 350px;
  overflow: hidden;
`

export const RecentGameItem = styled.div`
  text-decoration: none;
  display: grid;

  grid-template-columns: repeat(6, 1fr);
  grid-template-areas: 'win win bet bet roll roll' 'betamount betamount betamount payout payout payout';

  ${media.medium`
    grid-template-columns: 1fr 2fr 2fr 1fr 1fr;
    grid-template-areas: 'win betamount payout bet roll';
  `}
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;

  text-align: center;
  ${props =>
    props.area &&
    css`
      grid-area: ${props.area};
    `}

  ${media.medium`
        margin: auto 5px;
    `}
`

export const Value = styled.strong`
    margin-top: 5px;
    display: flex;
    font-size: 12px;
    align-items: baseline;
    justify-content: center;
    ${props =>
      props.center &&
      css`
        align-items: center;
        flex-direction: column;
      `}
    ${media.medium`
        font-size: 14px;
    `}
    ${media.large`
        word-wrap: break-word;
        max-width: 500px;
    `}
    
    ${props =>
      props.win &&
      css`
        color: green;
      `}
    
    ${props =>
      props.lose &&
      css`
        color: red;
      `}

    ${props =>
      props.small &&
      css`
        font-size: 13px;
      `}

  &:hover .tooltip {
    display: block;
    visibility: visible;
  }
`

export const RowLink = styled(Link)`
  text-decoration: none;
  height: 68px;
  display: grid;
  margin-bottom: 10px;
  padding: 5px 0;
  background: white;
  color: black;
  padding: 5px 0;
  border-radius: 5px;
`

export const Tooltip = styled.span`
  visibility: hidden;
  width: 120px;
  background-color: ${props => props.theme.highlight};
  color: ${props => props.theme.foreground};
  text-align: center;
  padding: 5px;
  border-radius: 6px;
  left: -8px;
  top: -34px;
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
`

export const Wintext = styled.div`
  position: relative;
  ${props =>
    props.isPending &&
    css`
      text-decoration: underline;
    `}
`
