// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import { BadgerBase } from 'badger-components-react'
import { Button } from './styled'

class BadgerButton extends React.Component {
  render() {
    const {
      handleClick,
      step,
      children,
      intl: { messages },
    } = this.props

    let content = ''

    switch (step) {
      case 'login':
        content = messages['badger-login']
        break
      case 'pending':
        content = messages['badger-pending']
        break
      case 'install':
        content = messages['badger-install']
        break
      default:
        content = children ? children : messages.Betinfo.badgerplay
        break
    }

    return (
      <Button {...this.props} onClick={handleClick}>
        {content}
      </Button>
    )
  }
}

export default BadgerBase(injectIntl(BadgerButton))
