import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { media } from 'styles/utils'
import Hero from 'components/Hero'
import ChanceSelectors from 'components/ChanceSelectors'
import WinList from 'components/WinList'
import Layout from 'components/Layout'
import ValueSelect from 'components/ValueSelect'
import RecentWins from 'components/RecentWins'
import PlayGame from 'components/PlayGame'
import GameTotals from 'components/GameTotals'
import Container from 'atoms/Container'
import Loading from 'atoms/Loading'

import { getSelectedGame } from 'data/gamerules/selectors'
import { fetchGamerules } from 'data/gamerules/actions'

import { fetchPrices } from 'data/prices/actions'
import { getPrices } from 'data/prices/selectors'
import {
  setSelectedAddress,
  setSelectedValue,
  toggleSoundMusic,
  toggleSoundSFX,
} from 'data/meta/actions'

import { fetchGameslist } from 'data/gameslist/actions'

const GameSection = styled.div`
  display: grid;
  color: ${props => props.theme.background};
  text-align: center;
  width: 100%;
  grid-column-gap: 10px;
  grid-template-columns: 1fr;
  grid-template-areas: 'settings' 'play' 'recent';

  ${media.medium`
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'settings play' 'recent recent';
  `}

  ${media.large`
    grid-column-gap: 0;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-areas: 'settings recent play';
  `}
`

const Settings = styled.div`
  grid-area: settings;
  margin-top: 10px;
  min-width: 260px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  color: ${props => props.theme.background};
  padding: 10px;
`

const Recent = styled.div`
  grid-area: recent;
  min-width: 260px;
  display: none;
  ${media.medium`
    display: block;
  `}
`
const Play = styled.div`
  grid-area: play;
  margin-top: 10px;
  min-width: 260px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.2);
  box-sizing: content-box;
  border-radius: 5px;
  color: ${props => props.theme.background};
`

class IndexPage extends React.Component {
  componentDidMount() {
    const { fetchGamerules, fetchPrices, fetchGameslist } = this.props

    fetchGamerules()
    fetchPrices()
    fetchGameslist('gamelog')
  }

  handleSetSelectedAddress = addr => {
    const { setSelectedAddress } = this.props
    setSelectedAddress(addr)
  }

  render() {
    const {
      pageContext: { locale },
      usdPrice,
      betInfoData,
    } = this.props

    const loading = !betInfoData.max || !usdPrice

    if (loading)
      return (
        <Layout
          locale={locale}
          layout="dice"
          addWinlistFn={this.handleAddToWinlist}
        >
          <Hero>
            <Loading />
            {/*  {!error && <Loading />}
            {error && <div>Errors loading page. Please try again later</div>}
            {error && errorMessage.map((item, index) => <div>{item}</div>)} */}
          </Hero>
        </Layout>
      )
    return (
      <Layout locale={locale} layout="dice">
        <Hero>
          <ChanceSelectors />
          <GameSection>
            <Settings>
              <ValueSelect />
            </Settings>
            <Recent>
              <GameTotals />
              <RecentWins />
            </Recent>
            <Play>
              <PlayGame />
            </Play>
          </GameSection>
        </Hero>
        <Container>
          <WinList />
        </Container>
      </Layout>
    )
  }
}

const mapStateToProps = state => {
  const { USD } = getPrices(state)
  const betInfoData = getSelectedGame(state)

  return {
    usdPrice: USD,
    betInfoData,
  }
}

const mapDispatchToProps = {
  fetchGamerules,
  setSelectedAddress,
  setSelectedValue,
  toggleSoundMusic,
  toggleSoundSFX,
  fetchPrices,
  fetchGameslist,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage)
