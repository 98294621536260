export const getGameslists = state => state.gameslist
export const getRecentGames = state => {
  if (state.gameslist.gamelog.fetching !== 0) return []

  const recentGames = state.gameslist.gamelog.data
    ? state.gameslist.gamelog.data.slice(0, 3).sort((a, b) => b.id - a.id)
    : []

  return recentGames
}
