import styled, { css } from 'styled-components'
import { media } from 'styles/utils'

export const TabsRow = styled.div`
  overflow: hidden;
  border: none;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  width: 100%;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    bottom: 1px;
    left: 0;
    z-index: -10;
    background: ${props => props.theme.highlight};
  }
`

export const TabButton = styled.button`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid ${props => props.theme.highlight};
  font-size: 14px;
  z-index: 1;
  outline: none;
  cursor: pointer;
  padding: 14px 5px;
  margin: 10px 5px 1px;
  transition: 0.3s;
  font-weight: 700;

  ${media.medium`
      font-size: 16px;
  `} ${media.large`
      padding: 14px 16px;
  `} &:hover {
    background-color: ${props => props.theme.background};
  }

  ${props =>
    props.selected
      ? css`
          background-color: ${props => props.theme.background};
          border-bottom-color: ${props => props.theme.background};
          color: ${props => props.theme.cascade};
        `
      : css`
          background-color: ${props => props.theme.rowBackground};
          border-bottom-color: ${props => props.theme.highlight};
        `};
`
