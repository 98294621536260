import styled from 'styled-components'

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

export const InputContainer = styled.div`
  margin-bottom: 15px;
  padding: 0 15px;
  display: grid;
  grid-template-columns: 3fr 1fr;
`

export const Input = styled.input`
  font-size: 18px;
  padding: 5px;
  width: 100%;
  text-align: center;
  background: rgba(255, 255, 255, 0.15);
  border: 0;
  color: #fff;
  box-sizing: border-box;

  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  &::-webkit-inner-spin-button {
    display: absolute;
    padding: 5px;
    margin-top: -5px;
    margin-right: -5px;
    margin-bottom: -5px;
  }
`

export const SliderContainer = styled.div`
  padding: 0 15px;

  .noUi-connect {
    background: ${props => props.theme.secondary};
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  margin: 25px 10px 0;
  justify-content: space-between;
  background: white;
  border-radius: 5px;
`

export const Button = styled.div`
  cursor: pointer;
  padding: 10px;
  color: black;
  width: 100%;
  &:nth-child(1) {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  &:nth-child(2) {
    border-left: 1px solid black;
  }
  &:nth-child(2),
  &:nth-child(3) {
    border-right: 1px solid black;
  }
  &:nth-child(4) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &:hover {
    background: ${props => props.theme.primary};
  }
`

export const Switch = styled.label`
  position: relative;
  display: inline-block;
  width: 70px;
  height: 34px;
  outline: none;
  cursor: pointer;

  &:before {
    position: absolute;
    content: 'BCH';
    color: ${props => props.theme.foreground};
    left: 7px;
    top: 50%;
    margin-top: -6px;
    font-size: 12px;
    z-index: 8;
  }

  &:after {
    position: absolute;
    content: 'USD';
    color: ${props => props.theme.foreground};
    top: 50%;
    right: 7px;
    margin-top: -6px;
    font-size: 12px;
  }

  input {
    position: absolute;
    display: none;
  }

  input:checked + span {
    background-color: ${props => props.theme.tertiary};
  }

  input:focus + span {
    box-shadow: 0 0 1px ${props => props.theme.shadow};
  }

  input:checked + span:before {
    -webkit-transform: translateX(31px);
    -ms-transform: translateX(31px);
    transform: translateX(31px);
  }
`

export const InputCheck = styled.input``

export const SwitchSlider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.theme.tertiary};
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 31px;
    left: 4px;
    bottom: 4px;
    background-color: ${props => props.theme.primary};
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
`
