import {
  FETCH_PRICES_BEGIN,
  FETCH_PRICES_FAIL,
  FETCH_PRICES_SUCCESS,
} from './constants'

import { PRICES_URL } from '../api'

const fetchPricesBegin = () => {
  return {
    type: FETCH_PRICES_BEGIN,
  }
}

const fetchPricesSuccess = data => {
  return {
    type: FETCH_PRICES_SUCCESS,
    payload: data,
  }
}

const fetchPricesFail = () => {
  return {
    type: FETCH_PRICES_FAIL,
  }
}

export const fetchPrices = () => {
  return async dispatch => {
    dispatch(fetchPricesBegin())
    try {
      const res = await fetch(PRICES_URL)
      const json = await res.json()
      const priceUSD = json.data.BCH
      dispatch(fetchPricesSuccess({ USD: priceUSD }))
    } catch (error) {
      console.error(error)
      dispatch(fetchPricesFail())
    }
  }
}
