import styled from 'styled-components'

export const Button = styled.button`
  margin: 0 10px;
  cursor: pointer;
  color: rgb(245, 147, 50);
  background: #222;
  border: 1px solid;
  padding: 9px;
  border-radius: 5px;
  display: block;
  border-color: rgb(245, 147, 50);
  animation: shadow-pulse 1s infinite;

  &:hover {
    animation: unset;
    background-color: rgb(245, 147, 50);
    color: rgb(255, 255, 255);
  }
`
