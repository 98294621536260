import React from 'react'
import { withTheme } from 'styled-components'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import H1 from 'atoms/H1'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import {
  IconCancel,
  IconCheck,
  IconTimer,
  IconHappy,
  IconSad,
} from 'atoms/Icons'
import { getRecentGames } from 'data/gameslist/selectors'
import { getPrices } from 'data/prices/selectors'
import { getTxids } from 'data/meta/selectors'
import { formatBCHValue } from 'styles/utils'
import {
  Wrapper,
  RecentGameItem,
  Item,
  Value,
  RowLink,
  Tooltip,
  Wintext,
} from './styled'

class RecentWins extends React.Component {
  shrinkTxHash(tx) {
    if (tx === undefined) return 'N/A'
    return tx.substring(0, Math.min(tx.length, 10)) + '...'
  }
  render() {
    const { recentwins, intl, price, txids } = this.props
    const { messages } = intl

    const items = recentwins.map((item, i) => {
      const winResult = item.win ? (
        txids.includes(item.depositTxHash) ? (
          <IconHappy />
        ) : (
          <IconCheck />
        )
      ) : item.broadcasted ? (
        <IconTimer />
      ) : txids.includes(item.depositTxHash) ? (
        <IconSad />
      ) : (
        <IconCancel />
      )
      let winResultText = ''
      let isPending = false
      if (item.win) {
        if (!item.broadcasted) {
          winResultText = messages.Winlist.pending
          isPending = true
        } else {
          winResultText = txids.includes(item.depositTxHash)
            ? messages.Winlist.playerWon
            : messages.Winlist.win
        }
      } else {
        if (item.roll === -1) {
          winResultText = messages.Winlist.invalid
        } else {
          winResultText = txids.includes(item.depositTxHash)
            ? messages.Winlist.playerLost
            : messages.Winlist.lose
        }
      }

      return (
        <RowLink to={`/fair?id=${item.id}`} key={item.id}>
          <RecentGameItem>
            <Item area="win">
              <Value center>
                {winResult}
                <Wintext isPending={isPending}>
                  {winResultText}
                  {isPending && (
                    <Tooltip className="tooltip">
                      Payout will occure with next confirmed block
                    </Tooltip>
                  )}
                </Wintext>
              </Value>
            </Item>
            <Item area="betamount">
              {messages.Winlist.betamount}
              <Value win={item.win} lose={!item.win}>
                {formatBCHValue(item.betAmount)} {messages.bch}
              </Value>
              <Value small>(${(item.betAmount * price).toFixed(2)} USD)</Value>
            </Item>
            <Item area="payout">
              {messages.Winlist.payoutamount}
              <Value win={item.win} lose={!item.win}>
                {formatBCHValue(item.payout)} {messages.bch}
              </Value>
              <Value small>(${(item.payout * price).toFixed(2)} USD)</Value>
            </Item>
            <Item area="bet">
              {messages.Winlist.bet}
              <Value>{item.bet}</Value>
            </Item>
            <Item area="roll">
              {messages.Winlist.roll}
              <Value>{item.roll === -1 ? 'N/A' : item.roll}</Value>
            </Item>
          </RecentGameItem>
        </RowLink>
      )
    })

    return (
      <Wrapper>
        <H1 thin>Recent Games</H1>
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={0}
        >
          {items}
        </ReactCSSTransitionGroup>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const recentwins = getRecentGames(state)
  const { USD } = getPrices(state)
  const txids = getTxids(state)
  return { recentwins, price: USD, txids }
}

export default connect(mapStateToProps)(withTheme(injectIntl(RecentWins)))
