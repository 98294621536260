// @flow

import React from 'react'
import { injectIntl } from 'react-intl'
import { connect } from 'react-redux'
import { getGamescount } from 'data/gamerules/selectors'
import H1 from 'atoms/H1'
import FlipCounter from 'components/FlipCounter'

class GameTotals extends React.Component {
  render() {
    const { gamesCount, intl } = this.props
    const { messages } = intl
    return (
      <div>
        <H1 thin>{messages.Information.totalbets}</H1>
        <FlipCounter count={gamesCount} />
      </div>
    )
  }
}

const mapStateToProps = state => {
  const gamesCount = getGamescount(state)
  return { gamesCount }
}

export default connect(mapStateToProps)(injectIntl(GameTotals))
