import styled, { css } from 'styled-components'
import BadgerButton from 'components/BadgerButton'

export const Wrapper = styled.div`
  padding-bottom: 10px;
`

export const ChanceSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
  width: 100%;
  margin: auto;
  justify-content: space-between;
`

export const ChanceBox = styled.div`
  ${props =>
    props.color &&
    css`
      background-color: ${props.color};
    `}
  border-radius: 5px;
  margin: auto;
  text-align: center;
  width: 100%;
  padding: 5px 10px;
  box-sizing: border-box;

  ${props =>
    props.button &&
    css`
      cursor: pointer;
      &:hover {
        animation: unset;
        background-color: rgb(245, 147, 50);
        color: rgb(255, 255, 255);
      }
    `}
`

export const ChanceTitle = styled.p`
  margin: 0;
  font-size: 12px;
  margin: 0;
  line-height: 1;
`

export const ChanceStat = styled.span`
  font-weight: 700;
  font-size: 12px;
  margin: 0;
  line-height: 1;
`

export const WinNumber = styled.div`
  width: 100%;
  text-align: center;
`
export const WinTitle = styled.div`
  margin: auto;
  width: 100%;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
`

export const WinAmount = styled.div`
  border: none;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  &:hover {
    animation: unset;
    background-color: rgb(245, 147, 50);
    color: rgb(255, 255, 255);
  }
`

export const StyledBadger = styled(BadgerButton)`
  text-size: 14px;
  animation: unset;
  margin: 10px auto;
  width: 100%;
`
