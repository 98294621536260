// @flow

import React from 'react'
import { Wrapper, Number } from './styled'

const numberWithCommas = x => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

class FlipCounter extends React.Component {
  render() {
    const { count } = this.props
    const numArray = count ? numberWithCommas(count).split('') : []
    return (
      <Wrapper>
        {numArray.map((letter, idx) => (
          <Number key={idx}>{letter}</Number>
        ))}
      </Wrapper>
    )
  }
}

export default FlipCounter
