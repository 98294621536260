import React from 'react'
import { withTheme } from 'styled-components'

import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import H1 from 'atoms/H1'

import { getSelectedValue } from 'data/meta/selectors'
import { getSelectedGame } from 'data/gamerules/selectors'
import { getPrices } from 'data/prices/selectors'

import { addTransactionId } from 'data/meta/actions'
import {
  Wrapper,
  ChanceSection,
  ChanceBox,
  ChanceTitle,
  ChanceStat,
} from './styled'

class BetChances extends React.Component {
  render() {
    const {
      intl: { messages },
      theme,
      maxClickFn,
      minClickFn,
      betInfoData,
    } = this.props
    const { min, max, betnumber, betmax } = betInfoData

    return (
      <Wrapper>
        <H1 thin>{messages.Betinfo.title}</H1>
        {/* <StyledBadger
          ref={ref => (this.badger = ref)}
          to={cashaddr}
          amount={selectedValue}
          successFn={res => {
            addTransactionId(res)
          }}
        >
          <WinAmount>
            <WinTitle>{messages.Betinfo.play}</WinTitle>
            <WinNumber>
              {winAmountBch} {messages.bch} (≈ {winAmountUsd} {messages.bch})
            </WinNumber>
          </WinAmount>
        </StyledBadger> */}
        <ChanceSection style={{ marginBottom: 10 }}>
          <ChanceBox color={theme.secondary}>
            <ChanceTitle>{messages.Betinfo.roll}</ChanceTitle>
            <ChanceStat>{betnumber}</ChanceStat>
          </ChanceBox>
          <ChanceBox color={theme.secondary}>
            <ChanceTitle>{messages.Betinfo.maxroll}</ChanceTitle>
            <ChanceStat>{betmax}</ChanceStat>
          </ChanceBox>
        </ChanceSection>
        <ChanceSection>
          <ChanceBox color={theme.tertiary} onClick={minClickFn} button>
            <ChanceTitle>{messages.Betinfo.minbet}</ChanceTitle>
            <ChanceStat>
              {min} {messages.bch}
            </ChanceStat>
          </ChanceBox>
          <ChanceBox color={theme.tertiary} onClick={maxClickFn} button>
            <ChanceTitle>{messages.Betinfo.maxbet}</ChanceTitle>
            <ChanceStat>
              {max} {messages.bch}
            </ChanceStat>
          </ChanceBox>
        </ChanceSection>
      </Wrapper>
    )
  }
}

const mapStateToProps = state => {
  const selectedValue = getSelectedValue(state)
  const betInfoData = getSelectedGame(state)
  const { USD } = getPrices(state)
  return { selectedValue, betInfoData, usdPrice: USD }
}

const mapDispatchToProps = {
  addTransactionId,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(injectIntl(BetChances)))
